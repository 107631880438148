@import '../../../../styles/basics';

.bb-featured-news {
  &:global(.bb) {
    @include bb-standalone-spacing();
  }
  position: relative;

  :global {
    .col-md-4 {
      .description {
        @include media-breakpoint-between(sm, md) {
          display: none;
        }
      }
      &:hover,
      a:focus-visible {
        h3.title {
          text-decoration: underline;
        }
      }
    }

    .counter {
      font-size: 16px;
      font-weight: $font-weight-bold;
      color: $slate-1200;
      position: absolute;
      bottom: 30px;
      left: calc(50% - 12px);

      &.with-button {
        bottom: 70px;
      }
    }

    [data-component^="button-"] {
      margin-bottom: 0;
    }

    .slick-slider {
      padding-bottom: 75px;

      .slick-arrow {
        background: url('/static/icons/carousel-button.svg') no-repeat;
        background-size: 40px;
        width: 40px;
        height: 40px;
        bottom: 0;
        top: auto;

        &:before {
          display: none;
        }

        &.slick-prev {
          left: calc(50% - 80px);
          -webkit-transform: rotate(180deg) translateY(20px);
          -moz-transform: rotate(180deg) translateY(20px);
          -ms-transform: rotate(180deg) translateY(20px);
          -o-transform: rotate(180deg) translateY(20px);
          transform: rotate(180deg) translateY(20px);
        }

        &.slick-next {
          right: calc(50% - 80px);
          -webkit-transform: rotate(360deg) translateY(-20px);
          -moz-transform: rotate(360deg) translateY(-20px);
          -ms-transform: rotate(360deg) translateY(-20px);
          -o-transform: rotate(360deg) translateY(-20px);
          transform: rotate(360deg) translateY(-20px);
        }

        &.slick-disabled {
          background-image: url('/static/icons/carousel-button-inactive.svg');
        }
      }
    }

    .item-with-image .top {
      background: $turquoise-1000;
      color: $white;

      @media all and (min-resolution: 1x) {
        img {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
        }
        // 380x200 image. 200 / 380 * 100 ~ 52.63
        padding-top: 52.63%;
      }

      @media all and (min-resolution: 2x) {
        // 560x290 image. 290 / 560 * 100 ~ 51.785
        padding-top: 51.785%;
      }

      @media all and (min-width: 576px) and (min-resolution: 1x) {
        // 560x290 image. 290 / 560 * 100 ~ 51.785
        padding-top: 51.785%;
      }

      @media all and (min-width: 576px) and (min-resolution: 2x) {
        // 790x440 image. 440 / 790 * 100 ~ 55.696
        padding-top: 55.696%;
      }

      @media all and (min-width: 991px) and (min-resolution: 1x) {
        // 380x200 image. 200 / 380 * 100 ~ 52.63
        padding-top: 52.63%;
      }
    }

    .col-md-8 .item-with-image-hoverable .item {
      position: relative;
      background: $turquoise-1000;
      color: $white;

      &:hover,
      a:focus-visible {
        h3.title {
          text-decoration: underline;
        }
      }

      @media all and (min-width: 991px) and (min-resolution: 1x) {
        img {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
        }
        // 790x440 image. 440 / 790 * 100 ~ 55.696
        padding-top: 55.696%;
      }
    }
  }
}
