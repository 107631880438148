@import '../../../../styles/basics';

.item-with-image-hoverable {
  :global {
    .item {
      display: block;
      position: relative;

      @include media-breakpoint-up(lg) {
        &:hover {
          .hoverable {
            background-color: $turquoise-1000;
          }

          .description {
            padding: 16px 0;
            max-height: 500px !important;
            opacity: 1 !important;
          }
        }
      }

      img {
        width: 100%;
      }

      .hoverable {
        margin-right: 100px;
        padding: 20px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $turquoise-1000;

        .title {
          color: $white;
        }

        .description {
          color: $white;
          max-height: 0;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          overflow: hidden;

          p {
            color: $white;
            margin: 0;
          }
        }

        .top-line {
          margin-bottom: 16px;

          & > * {
            color: $white;
          }

          .date {
            padding-left: 16px;
          }
        }
      }
    }
  }
}
